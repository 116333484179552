import { Component, OnInit } from '@angular/core';
import { Chart } from "chart.js";

import * as moment from 'moment';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import { CCService } from 'src/app/services/cCService/c-c.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  allScheme;
  selectSchemeCode;
  allDistrict;
  mainSec:boolean= false;
  sehemeSec = 'show-anim';
  districtSec = 'hide-anim';
  piaSec = 'hide-anim'; 
  sdcSec = 'hide-anim';
  selectDistrictName;
  allSdc;
  allPia;
  sdcList;
  schemeSdcList;
  finalSDCList;
  districtSdcList;
  selectPiaName;
  piaSdcList;
  allData =[];
  seleSdcAccessId;
  sideCount;
  LineChart: any;
  selectFilterType= 'scheme';
  selectStep = 'scheme';
  streamDashSec: boolean = true;
  piaAccessId;;
  sdcListSec: boolean = true;
  schemeCss;
  distCss;
  piaCss;
  sdcMainSearch;
  constructor(
    private router: Router,
    private adminService: AdminServiceService,
    private centerService: CenterDetailsService,
    private piaService: PiaServiceService,
    private ccService: CCService
  ) { }

  /* ===============================================================
      Get All SDC list
  =============================================================== */
  getAllSDCList() {
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc);
      this.sdcList = sdc.centerDetails;
      this.adminService.getScheme().subscribe((data: any) => {
        //console.log(data);
        this.allScheme = data.schemeDetails;
      });
    });
  }

  /* ===============================================================
      OnClick Scheme
  =============================================================== */
  onClickScheme() {
    this.sehemeSec = 'show-anim';
    this.districtSec = 'hide-anim';
    this.piaSec = 'hide-anim';
    this.sdcSec = 'hide-anim';
    this.selectStep = 'scheme';
    this.seleSdcAccessId = undefined;
    this.sdcMainSearch= '';
    this.streamDashSec = true;
    if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
      this.finalSDCList = undefined;
      this.graphLoad();
    } else { }
  }

  /* On Select Scheme */
  onSelectScheme(scheme) {
    this.selectSchemeCode = scheme.schemeCode;
    this.selectFilterType = 'scheme';

    this.sdcListSec = true;
    this.streamDashSec = true;
    this.selectPiaName = undefined;
    this.piaSdcList = undefined;
    this.sdcMainSearch= '';
    if (this.selectDistrictName != undefined) {
      this.schemeSdcList = this.districtSdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.schemeCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else if (this.selectPiaName != undefined) {
      this.schemeSdcList = this.piaSdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.schemeCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else {
      this.schemeSdcList = this.sdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      //console.log(this.finalSDCList);
      this.seleSdcAccessId = undefined;
      this.selectDistrictName = undefined;
      this.selectPiaName = undefined;
      this.schemeCss = 'first-s';
    }
  }

  /* ===============================================================
      OnClick District
  =============================================================== */
  onClickDistrict() {
    this.centerService.getAllDistrict().subscribe((district: any) => {
      //console.log(district);
      this.allDistrict = district.districtDetails;
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectStep = 'dist';
      this.seleSdcAccessId = undefined;
      this.streamDashSec = true;
      this.sdcMainSearch= '';
      if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
        this.finalSDCList = undefined;
        this.graphLoad();
      } else { }
    });
  }

  /* On Select District */
  onSelectDistrict(district) {
    this.selectDistrictName = district.districtName;
    this.sdcListSec = true;
    this.streamDashSec = true;
    this.seleSdcAccessId = undefined;
    this.selectFilterType = 'dist';
    this.sdcMainSearch= '';
    if (this.selectSchemeCode === undefined) {
      this.districtSdcList = this.sdcList.filter(ele => ele.districtName === this.selectDistrictName);
      this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.distCss = 'first-s';
      //console.log(this.finalSDCList);
    } else if (this.selectSchemeCode != undefined) {
      this.districtSdcList = this.schemeSdcList.filter(ele => ele.districtName === this.selectDistrictName);
      this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.distCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else { }
  }

  /* ===============================================================
      OnClick Pia/TP
  =============================================================== */
  onClickPia() {
    this.piaService.getAllTP().subscribe((pia: any) => {
      //console.log(pia);
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'show-anim';
      this.sdcSec = 'hide-anim';
      this.selectStep = 'pia';
      this.seleSdcAccessId = undefined;
      this.streamDashSec = true;
      this.sdcMainSearch= '';
      if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
        this.finalSDCList = undefined;
        this.graphLoad();
      } else { }
      if (this.selectSchemeCode != undefined) {
        if (this.selectDistrictName != undefined) {
          var schemePiaList = pia.tpDetails.filter(ele => ele.schemeCode === this.selectSchemeCode);
          var distPiaList = schemePiaList.filter(ele => ele.districtName === this.selectDistrictName);
          this.allPia = distPiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
          //console.log(this.allPia)
        } else {
          var schemePiaList = pia.tpDetails.filter(ele => ele.schemeCode === this.selectSchemeCode);
          this.allPia = schemePiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
          //console.log(this.allPia);
        }
      } else if (this.selectDistrictName != undefined) {
        var distPiaList = pia.tpDetails.filter(ele => ele.districtName === this.selectDistrictName);
        this.allPia = distPiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
        //console.log(this.allPia);
      } else {
        this.allPia = pia.tpDetails.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
        //console.log(this.allPia)
      }
    });
  }

  /* On Select Pia/TP */
  onSelectPia(pia) {
    //console.log(pia);
    //console.log(this.sdcList)
    this.selectPiaName = pia.tpShortName;
    this.piaAccessId = pia.accessId;
    this.sdcListSec = true;
    this.streamDashSec = true;
    this.seleSdcAccessId = undefined;
    this.selectFilterType = 'pia';
    this.sdcMainSearch= '';
    if (this.selectSchemeCode != undefined) {
      if (this.selectDistrictName != undefined) {
        this.piaSdcList = this.districtSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
        this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        this.piaCss = 'third-s';
        //console.log(this.finalSDCList);
      } else {
        this.piaSdcList = this.schemeSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
        this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        this.piaCss = 'sec-s';
        //console.log(this.finalSDCList);
      }
    } else if (this.selectDistrictName != undefined) {
      this.piaSdcList = this.districtSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
      this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.piaCss= 'sec-s';
      //console.log(this.finalSDCList);
    } else {
      this.piaSdcList = this.sdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
      this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.piaCss = 'first-s';
      //console.log(this.finalSDCList);
    }
  }

  schemeClose() {
    this.schemeCss = undefined;
    this.sdcMainSearch= '';
    if (this.selectSchemeCode != undefined) {
      if (this.selectDistrictName != undefined) {
        if (this.selectFilterType == 'scheme') {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectSchemeCode = undefined;
          //console.log(this.finalSDCList);
          this.selectStep = 'dist';
        } else {
          if (this.selectPiaName != undefined) {
            this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            this.selectSchemeCode = undefined;
            this.selectPiaName = undefined
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'pia';
            this.selectPiaName = undefined;
            this.selectStep = 'scheme';
            this.graphLoad();
            this.finalSDCList = undefined;
            //console.log(this.finalSDCList);
          } else {
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectStep = 'scheme';
            this.finalSDCList = undefined;
            this.selectSchemeCode = undefined;
            this.selectDistrictName = undefined;
            this.selectPiaName = undefined;
            this.graphLoad();
            //console.log(this.finalSDCList);
          }
        }
      } else if (this.selectPiaName != undefined) {
        if (this.selectFilterType == 'scheme') {
          this.finalSDCList = this.piaSdcList;
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'show-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'pia';
          this.selectSchemeCode = undefined;
          //console.log(this.finalSDCList)
        } else {
          this.sehemeSec = 'show-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'scheme';
          this.finalSDCList = undefined;
          this.selectSchemeCode = undefined;
          this.selectDistrictName = undefined;
          this.selectPiaName = undefined
          this.selectStep = 'scheme';
          this.graphLoad();
          //console.log(this.finalSDCList);
        }
      } else {
        this.finalSDCList = undefined;
        this.sehemeSec = 'show-anim';
        this.districtSec = 'hide-anim';
        this.piaSec = 'hide-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'scheme';
        this.selectStep = 'scheme';
        this.selectSchemeCode = undefined;
        this.selectDistrictName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad();
      }
    } else {
      this.finalSDCList = undefined;
      this.sehemeSec = 'show-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'scheme';
      this.selectStep = 'scheme';
      this.selectPiaName = undefined;
      this.selectDistrictName = undefined;
      //console.log(this.finalSDCList)
      this.graphLoad();
    }
  }

  districtClose() {
    this.distCss = undefined;
    this.sdcMainSearch= '';
    if (this.selectDistrictName != undefined) {
      if (this.selectSchemeCode != undefined) {
        if (this.selectFilterType == 'dist') {
          this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'show-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'scheme';
          this.selectStep = 'scheme';
          this.selectDistrictName = undefined;
          //console.log(this.finalSDCList);
        } else {
          if (this.selectPiaName != undefined) {
            this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectPiaName = undefined;
            this.selectDistrictName = undefined;
            this.selectStep = 'scheme';
            //console.log(this.finalSDCList)
          } else {
            this.finalSDCList = undefined;
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'dist';
            this.selectSchemeCode = undefined;
            this.selectDistrictName = undefined;
            this.selectPiaName = undefined;
            this.graphLoad()
            //console.log(this.finalSDCList)
          }
        }
      } else {
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'show-anim';
        this.piaSec = 'hide-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'dist';
        this.finalSDCList = undefined;
        this.districtSdcList = undefined;
        this.sdcListSec = false;
        this.streamDashSec = true;
        this.selectDistrictName = undefined;
        this.selectPiaName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad()
      }
    } else {
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'dist';
      this.finalSDCList = undefined;
      this.districtSdcList = undefined;
      this.sdcListSec = false;
      this.streamDashSec = true;
      this.selectDistrictName = undefined;
      this.graphLoad()
      //console.log(this.finalSDCList)
    }
  }

  piaClose() {
    this.piaCss = undefined;
    this.sdcMainSearch= '';
    if (this.selectPiaName != undefined) {
      if (this.selectSchemeCode != undefined) {
        if (this.selectDistrictName != undefined) {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectStep = 'dist';
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
        } else {
          if (this.selectFilterType == 'pia') {
            this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectStep = 'scheme';
            this.selectPiaName = undefined;
            //console.log(this.finalSDCList);
          } else {
            this.finalSDCList = undefined;
            this.sehemeSec = 'hide-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'show-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'pia';
            this.selectSchemeCode = undefined;
            this.selectPiaName = undefined;
            this.graphLoad();
            //console.log(this.finalSDCList);
          }
        }
      } else if (this.selectDistrictName != undefined) {
        if (this.selectFilterType == 'pia') {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
        } else {
          this.finalSDCList = undefined;
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'pia';
          this.selectDistrictName = undefined;
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
          this.graphLoad();
        }
      } else {
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'hide-anim';
        this.piaSec = 'show-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'pia';
        this.finalSDCList = undefined;
        this.districtSdcList = undefined;
        this.sdcListSec = false;
        this.streamDashSec = true;
        this.selectPiaName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad()
      }
    } else {
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'dist';
      this.finalSDCList = undefined;
      this.districtSdcList = undefined;
      this.sdcListSec = false;
      this.streamDashSec = true;
      this.selectDistrictName = undefined;
      this.graphLoad()
      //console.log(this.finalSDCList)
    }
  }

  /* ===============================================================
      OnClick SDC
  =============================================================== */
  onClickSdc() {
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc);
      this.allSdc = sdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'show-anim';
      this.selectStep = 'sdc';
      this.sdcMainSearch= '';
    });
  }

  /* On Select SDC */
  onSelectSdc(sdc) {
    //console.log(sdc);
    
    this.seleSdcAccessId = sdc.accessId;
    if(this.selectDistrictName === undefined){
      this.selectDistrictName = 'N/A';
    }else{}
    if(this.selectPiaName ===  undefined){
      this.selectPiaName = 'N/A';
    }else{}
    if(this.selectSchemeCode === undefined){
      this.selectSchemeCode = 'N/A';
    }
    this.allData.push({
      schemeCode: this.selectSchemeCode, districtName: this.selectDistrictName, piaName: this.selectPiaName, sdcAccessId: sdc.accessId, sdcCode: sdc.centerCode, sdcName: sdc.centerName,
      sdcLocation: sdc.location, sdcDistrict: sdc.districtName
    });
    //console.log(this.allData)
    localStorage.setItem('allData', JSON.stringify(this.allData));
    this.router.navigate(['/sdcdetail']);
  }

  graphLoad(){
    this.adminService.getADSideCount(moment().format('YYYY-MM-DD')).subscribe((data:any)=>{
      //console.log(data);
      this.sideCount = data;
      //console.log(this.sideCount.allRegisteredStudent);
      this.LineChart = new Chart('mainchart', {
        type: 'bar',
        data: {
          labels: ["Resources", "Student", "Teacher", "Staff"],
          datasets: [{
            label: '',
            data: [this.sideCount.allRegisteredStudent + this.sideCount.allRegisteredTeacher + this.sideCount.allRegisteredEmp,
            this.sideCount.allRegisteredStudent, this.sideCount.allRegisteredTeacher, this.sideCount.allRegisteredEmp],
            barThickness: 80,
            backgroundColor: [
              'rgba(52, 73, 94,0.6)',
              'rgba(52, 152, 219,0.6)',
              'rgba(230, 126, 34,0.6)',
              'rgba(155, 89, 182,0.6)',
              ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false,

          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('studentChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.allRegisteredStudent, this.sideCount.attendanceCountStudent, this.sideCount.allRegisteredStudent-this.sideCount.attendanceCountStudent],
            barThickness: 30,
            backgroundColor: [
              'rgba(52, 152, 219,0.9)',
              'rgba(52, 152, 219,0.6)',
              'rgba(52, 152, 219,0.3)',
              ],
          }]
        },
        options: {
          tooltips: {
            position:'average',
            axis: 'x',
          },
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false,
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('teacherChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.allRegisteredTeacher, this.sideCount.attendanceCountTeacher, this.sideCount.allRegisteredTeacher-this.sideCount.attendanceCountTeacher],
            barThickness: 30,
            backgroundColor: [
              'rgba(230, 126, 34,0.9)',
              'rgba(230, 126, 34,0.6)',
              'rgba(230, 126, 34,0.3)',
              ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('staffChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.allRegisteredEmp, this.sideCount.attendanceCountEmp, this.sideCount.allRegisteredEmp-this.sideCount.attendanceCountEmp],
            barThickness: 30,
            backgroundColor: [
              'rgba(155, 89, 182,0.9)',
              'rgba(155, 89, 182,0.6)',
              'rgba(155, 89, 182,0.3)',
              ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
    })
  }

  somethingChanged(data) {
    if (data.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  leftNavPosition(){
    if(localStorage.getItem('selectType') != null){
      if(localStorage.getItem('selectType') === 'scheme'){
        this.onClickScheme();
        setTimeout(() => {
          localStorage.removeItem('selectType');
        }, 1000);
      }else if(localStorage.getItem('selectType') === 'dist'){
        this.onClickDistrict();
        setTimeout(() => {
          localStorage.removeItem('selectType');
        }, 1000);
      }else if(localStorage.getItem('selectType') === 'pia'){
        this.onClickPia();
        setTimeout(() => {
          localStorage.removeItem('selectType');
        }, 1000);
      }else{}
    }else{
      //console.log('ss');
      
    }
  }

  ngOnInit() {
    this.getAllSDCList();
    this.graphLoad();
    this.leftNavPosition();
  }

  ngAfterViewInit() {
  }

}
