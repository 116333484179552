import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { NgxGaugeModule } from 'ngx-gauge';
import { ToastrModule } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle';
import { IMqttMessage,  MqttModule,  IMqttServiceOptions } from 'ngx-mqtt';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { VgStreamingModule } from 'videogular2/streaming';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { ChartsModule } from 'ng2-charts';
import { BatchSearchPipe } from './search/batchSearch/batch-search.pipe';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreateAdminComponent } from './components/create-admin/create-admin.component';
import { SuperadminDashboardComponent } from './components/superadmin-dashboard/superadmin-dashboard.component';
import { SuperadminNavComponent } from './components/superadmin-nav/superadmin-nav.component';
import { VerifyComponent } from './components/verify/verify.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminNavComponent } from './components/admin-nav/admin-nav.component';
import { CreatePiaComponent } from './components/create-pia/create-pia.component';
import { PiaDashboardComponent } from './components/pia-dashboard/pia-dashboard.component';
import { PiaListComponent } from './components/pia-list/pia-list.component';
import { PiaNavComponent } from './components/pia-nav/pia-nav.component';
import { CenterDashboardComponent } from './components/center-dashboard/center-dashboard.component';
import { CreateClassComponent } from './components/create-class/create-class.component';
import { HistoryComponent } from './components/history/history.component';
import { CenterListComponent } from './components/center-list/center-list.component';
import { CreateCenterComponent } from './components/create-center/create-center.component';
import { CenterNavComponent } from './components/center-nav/center-nav.component';
import { AttendanceComponent } from './components/attendance/attendance.component';
import { LiveStreamComponent } from './components/live-stream/live-stream.component';
import { CourseComponent } from './components/course/course.component';
import { ReportComponent } from './components/report/report.component';
import { CreateResourcesComponent } from './components/create-resources/create-resources.component';
import { ResourcesListComponent } from './components/resources-list/resources-list.component';
import { SelectClassComponent  } from './components/select-class/select-class.component';
import { AutoLogoutComponent } from './components/auto-logout/auto-logout.component';
import { ResourceComponent } from './components/resource/resource.component';
import { AuditComponent } from './components/audit/audit.component';
import { CCDashboardComponent } from './components/c-cdashboard/c-cdashboard.component';
import { CCAutoPlayComponent } from './components/c-cauto-play/c-cauto-play.component';
import { CCVideoWallComponent } from './components/c-cvideo-wall/c-cvideo-wall.component';
import { CCSearchComponent } from './components/c-csearch/c-csearch.component';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from './services/centerService/center-details.service';
import { PiaServiceService } from './services/piaService/pia-service.service';
import { ImageCaptureService } from 'src/app/services/image-capture.service';
import { VideoRecordService } from 'src/app/services/video-record.service';
import { CCService } from './services/cCService/c-c.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { NotAuthGuard } from 'src/app/guards/not-auth.guard';


import { SearchPipe } from './search/search.pipe';
import { ResSearchPipe } from './searchRes/res-search.pipe';
import { CCenterPipe } from './search/cCSearch/c-center.pipe';
import { TpSearchPipe } from './search/tpSearch/tp-search.pipe';
import { SdcDetailsComponent } from './components/sdc-details/sdc-details.component';
import { DistSearchPipe } from './search/distirctSearch/dist-search.pipe';
import { CCOnlyLiveComponent } from './components/c-conly-live/c-conly-live.component';
import { CameraListComponent } from './components/camera-list/camera-list.component';
import { CameraSerachPipe } from './search/camreList/camera-serach.pipe';
import { VerifySearchPipe } from './search/verifySearch/verify-search.pipe';
import { AllPiaListComponent } from './components/all-pia-list/all-pia-list.component';
import { AllSdcListComponent } from './components/all-sdc-list/all-sdc-list.component';
import { AllCameraListComponent } from './components/all-camera-list/all-camera-list.component';
import { AllResListComponent } from './components/all-res-list/all-res-list.component';
import { AllSdcDetailComponent } from './components/all-sdc-detail/all-sdc-detail.component';
import { RsldcResListComponent } from './components/rsldc-res-list/rsldc-res-list.component';
import { UserUpdateComponent } from './components/user-update/user-update.component';
import { AddResourceComponent } from './components/add-resource/add-resource.component';
import { UserUpdatePipe } from './search/userUpdate/user-update.pipe';
import { SchemeSerPipe } from './search/scheme/scheme-ser.pipe';
import { SdcUpdateComponent } from './components/sdc-update/sdc-update.component';
import { SectorSerPipe } from './search/sectorSer/sector-ser.pipe';
import { AzureBlobStorageService } from './services/azure/azure-blob-storage.service';
import { SdcReportComponent } from './components/sdc-report/sdc-report.component';
import { ResourceReportComponent } from './components/resource-report/resource-report.component';
import { EditPiaComponent } from './components/edit-pia/edit-pia.component';
import { CommandHaryanaComponent } from './components/command-haryana/command-haryana.component';
import { HistoryAllComponent } from './components/history-all/history-all.component';
import { AllBatchListComponent } from './components/all-batch-list/all-batch-list.component';
import { SafePipe } from './search/safe.pipe';

export const MY_MOMENT_FORMATS = {
  parseInput: 'DD MMM YYYY',
  fullPickerInput: 'DD MMM YYYY',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'DD MMM YYYY',
  dateA11yLabel: 'DD MMM YYYY',
  monthYearA11yLabel: 'DD MMM YYYY',
};

export function getToken(): string {
  return localStorage.getItem('token');
}
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: '104.211.159.217',
  port: 8083,
  path: '/mqtt'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CreateAdminComponent,
    SuperadminDashboardComponent,
    SuperadminNavComponent,
    VerifyComponent,
    AdminDashboardComponent,
    AdminNavComponent,
    CreatePiaComponent,
    PiaDashboardComponent,
    PiaListComponent,
    CenterDashboardComponent,
    PiaNavComponent,
    CenterListComponent,
    CreateCenterComponent,
    CenterNavComponent,
    CreateClassComponent,
    HistoryComponent,
    AttendanceComponent,
    LiveStreamComponent,
    CourseComponent,
    CreateResourcesComponent,
    ResourcesListComponent,
    SelectClassComponent,
    AutoLogoutComponent,
    ResourceComponent,
    AllBatchListComponent,
    ReportComponent,
    CCDashboardComponent,
    CCAutoPlayComponent,
    CCVideoWallComponent,
    CCSearchComponent,
    AuditComponent,
    SearchPipe,
    ResSearchPipe,
    CCenterPipe,
    TpSearchPipe,
    SdcDetailsComponent,
    DistSearchPipe,
    CCOnlyLiveComponent,
    CameraListComponent,
    CameraSerachPipe,
    VerifySearchPipe,
    AllPiaListComponent,
    AllSdcListComponent,
    AllCameraListComponent,
    AllResListComponent,
    AllSdcDetailComponent,
    RsldcResListComponent,
    UserUpdateComponent,
    AddResourceComponent,
    UserUpdatePipe,
    SchemeSerPipe,
    SdcUpdateComponent,
    SectorSerPipe,
    SdcReportComponent,
    ResourceReportComponent,
    EditPiaComponent,
    CommandHaryanaComponent,
    HistoryAllComponent,
    BatchSearchPipe,
    SafePipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlMomentDateTimeModule,
    NgxGaugeModule,
    ChartsModule,
    ToastrModule.forRoot({
      timeOut:3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken
      }
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    AngularEditorModule,
  ],
  providers: [
    AuthService,
    CenterDetailsService,
    PiaServiceService,
    ImageCaptureService,
    VideoRecordService,
    CCService,
    AuthGuard,
    NotAuthGuard,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    DatePipe,
    JwtHelperService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    BnNgIdleService,
    AzureBlobStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
