import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { NotAuthGuard } from 'src/app/guards/not-auth.guard';

import { LoginComponent } from 'src/app/components/login/login.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { SuperadminDashboardComponent } from './components/superadmin-dashboard/superadmin-dashboard.component';
import { CreateAdminComponent } from './components/create-admin/create-admin.component';
import { VerifyComponent } from './components/verify/verify.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { CreatePiaComponent } from './components/create-pia/create-pia.component';
import { PiaDashboardComponent } from './components/pia-dashboard/pia-dashboard.component';
import { PiaListComponent } from './components/pia-list/pia-list.component';
import { CreateCenterComponent } from './components/create-center/create-center.component';
import { CenterDashboardComponent } from './components/center-dashboard/center-dashboard.component';
import { CenterListComponent } from './components/center-list/center-list.component';
import { HistoryComponent } from './components/history/history.component';
import { CreateClassComponent } from './components/create-class/create-class.component';
import { AttendanceComponent } from './components/attendance/attendance.component';
import { CourseComponent } from './components/course/course.component';
import { CreateResourcesComponent } from './components/create-resources/create-resources.component';
import { ResourcesListComponent } from './components/resources-list/resources-list.component';
import { LiveStreamComponent } from './components/live-stream/live-stream.component';
import { SelectClassComponent } from './components/select-class/select-class.component';
import { AutoLogoutComponent } from './components/auto-logout/auto-logout.component';
import { ResourceComponent } from './components/resource/resource.component';
import { AuditComponent } from './components/audit/audit.component';
import { ReportComponent } from './components/report/report.component';
import { CCDashboardComponent } from './components/c-cdashboard/c-cdashboard.component';
import { CCAutoPlayComponent } from './components/c-cauto-play/c-cauto-play.component';
import { CCSearchComponent } from './components/c-csearch/c-csearch.component';
import { CCVideoWallComponent } from './components/c-cvideo-wall/c-cvideo-wall.component';
import { SdcDetailsComponent } from './components/sdc-details/sdc-details.component';
import { CCOnlyLiveComponent } from './components/c-conly-live/c-conly-live.component';
import { CameraListComponent } from './components/camera-list/camera-list.component';
import { AllPiaListComponent } from './components/all-pia-list/all-pia-list.component';
import { AllSdcListComponent } from './components/all-sdc-list/all-sdc-list.component';
import { AllCameraListComponent } from './components/all-camera-list/all-camera-list.component';
import { AllResListComponent } from './components/all-res-list/all-res-list.component';
import { AllSdcDetailComponent } from './components/all-sdc-detail/all-sdc-detail.component';
import { RsldcResListComponent } from './components/rsldc-res-list/rsldc-res-list.component';
import { UserUpdateComponent } from './components/user-update/user-update.component';
import { AddResourceComponent } from './components/add-resource/add-resource.component';
import { SdcUpdateComponent } from './components/sdc-update/sdc-update.component';
import { SdcReportComponent } from './components/sdc-report/sdc-report.component';
import { ResourceReportComponent } from './components/resource-report/resource-report.component';
import { EditPiaComponent } from './components/edit-pia/edit-pia.component';
import { CommandHaryanaComponent } from './components/command-haryana/command-haryana.component';
import { HistoryAllComponent } from './components/history-all/history-all.component';
import { AllBatchListComponent } from './components/all-batch-list/all-batch-list.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [NotAuthGuard]
    },{
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },{

        path: 'cameraList',
        component: CameraListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'cCDashboard',
        component: CCDashboardComponent,
        canActivate: [AuthGuard]
    },{
        path: 'cCAutoPlay',
        component: CCAutoPlayComponent,
        canActivate: [AuthGuard]
    },{
        path: 'cCSearch',
        component: CCSearchComponent,
        canActivate: [AuthGuard]
    },{
        path: 'cCVideoWall',
        component: CCVideoWallComponent,
        canActivate: [AuthGuard]
    },{
        path: 'cCOnlyLive',
        component: CCOnlyLiveComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allPiaList',
        component: AllPiaListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allResources',
        component: RsldcResListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'addResources',
        component: AddResourceComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allSdcList',
        component: AllSdcListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'sdcUpdate',
        component: SdcUpdateComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allCameraList',
        component: AllCameraListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allResourcesList',
        component: AllResListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'allSdcDetail',
        component: AllSdcDetailComponent,
        canActivate: [AuthGuard]
    },{
        path: 'sdc_report',
        component: SdcReportComponent,
        canActivate: [AuthGuard]
    },{
        path: 'SUDashboard',
        component: SuperadminDashboardComponent,
        canActivate: [AuthGuard]
    },{
        path: 'createAdmin',
        component: CreateAdminComponent,
        canActivate: [AuthGuard]
    },{
        path: 'sdcdetail',
        component: SdcDetailsComponent,
        canActivate: [AuthGuard]
    },{
        path: 'verify',
        component: VerifyComponent,
        canActivate: [AuthGuard]
    },{
        path: 'adminDashboard',
        component: AdminDashboardComponent,
        canActivate: [AuthGuard]
    }, 
    {
        path: 'piaCreation',
        component: CreatePiaComponent,
        canActivate: [AuthGuard]
    },{
        path: 'piaDashboard/u/:userType',
        component: PiaDashboardComponent,
        canActivate: [AuthGuard]
    },{
        path: 'piaList',
        component: PiaListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'centerDashboard/u/:userType',
        component: CenterDashboardComponent,
        canActivate: [AuthGuard]
    },{
        path: 'centerCreation',
        component: CreateCenterComponent,
        canActivate: [AuthGuard]
    },{
        path: 'centersList',
        component: CenterListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'history/u/:userType',
        component: HistoryComponent,
        canActivate: [AuthGuard]
    },{
        path: 'history-all',
        component: HistoryAllComponent,
        canActivate: [AuthGuard]
    },{
        path: 'audit',
        component: AuditComponent,
        canActivate: [AuthGuard]
    },{
        path: 'class',
        component: CreateClassComponent,
        canActivate: [AuthGuard]
    },{
        path: 'selectClass',
        component: SelectClassComponent,
        canActivate: [AuthGuard]
    },{
        path: 'course',
        component: CourseComponent,
        canActivate: [AuthGuard]
    },{
        path: 'createResources',
        component: CreateResourcesComponent,
        canActivate: [AuthGuard]
    },{
        path: 'resourcesList',
        component: ResourcesListComponent,
        canActivate: [AuthGuard]
    },{
        path: 'resource',
        component: ResourceComponent,
        canActivate: [AuthGuard]
    },{
        path: 'resource-report/:accessId',
        component: ResourceReportComponent,
        canActivate: [AuthGuard]
    },{
        path: 'liveStream/u/:userType',
        component: LiveStreamComponent,
        canActivate: [AuthGuard]
    },{
        path: 'attendance/u/:userType',
        component: AttendanceComponent,
        canActivate: [AuthGuard]
    },{
        path: 'autoLogout',
        component: AutoLogoutComponent,
        canActivate: [NotAuthGuard]
    },{
        path: 'report',
        component: ReportComponent,
        canActivate: [AuthGuard]
    },{
        path: 'userUpdate',
        component: UserUpdateComponent,
        canActivate: [AuthGuard]
    },{
        path: 'editPia',
        component: EditPiaComponent,
        canActivate: [AuthGuard]
    },{
        path: 'command-center',
        component: CommandHaryanaComponent,
        canActivate: [AuthGuard]
    },{
        path: 'editBatch',
        component: AllBatchListComponent,
        canActivate: [AuthGuard]
    },
    //{
    //     path: 'history/:accessId/:streamName/:date',
    //     component: HistoryComponent,
    //     canActivate: [AuthGuard]
    // }, {
    //     path: 'livestream/:accessId/:streamName',
    //     component: LiveStreamComponent,
    //     canActivate: [AuthGuard]
    // },
     {
        path: '**',
        component: LoginComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
