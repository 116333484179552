import { Component, OnInit, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';
import { CCService } from 'src/app/services/cCService/c-c.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
declare var jwplayer: any;
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-c-conly-live',
  templateUrl: './c-conly-live.component.html',
  styleUrls: ['./c-conly-live.component.css']
})
export class CCOnlyLiveComponent implements OnInit {

  setValue = 0;
  cameraList=[];
  finalCameraList;
  totalOnCam;
  totalCamList;
  selectVidForZoom;
  loader: boolean;
  allSdc;
  allSortSdc;
  totalSdc;
  selectSdc;
  intereval;
  autoPlay: boolean;
  systemCheck: boolean = false;
  systemCheckTime;
  allCamera;
  onStep;
  totalCamera;
  selectClass;
  idAdd = '';

  disableBtn
  datetime;
  captureImageSec: boolean;
  username;
  startRecBtn: boolean = true;
  stopRecBtn: boolean = false;
  intervel;
  capturedImageUrl;


  constructor(
    private router: Router,
    private _compiler: Compiler,
    private sanitizer: DomSanitizer,
    private cCService: CCService,
    private toastr: ToastrService,
    private centerService: CenterDetailsService,
    private classService: ClassService,
    private authService: AuthService
  ) { }

  onClickSearch() {
    this.router.navigate(['/cCSearch']);
    localStorage.removeItem('locSetValue');
    localStorage.removeItem('totalCamList');
  }

  onClickAutoPlay() {
    this.router.navigate(['/cCAutoPlay']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
    localStorage.removeItem('locSetValue');
  }

  onClickAllHistory() {
    this.router.navigate(['/history-all']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  onClickLogout() {
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  onClickVideoZoom(data) {
    //console.log(data)
    this.selectVidForZoom = data.cameraDeviceId;
    this.selectClass = data;
    this.idAdd = 'active-c';
    this.pause();
  }

  onClickVidZomClo() {
    this.selectVidForZoom = undefined;
  }

  onClickSystemCheck() {
    this.cCService.systemCheck().subscribe((data: any) => {
      if (data.success === 1) {
        //console.log(data);
        this.systemCheck = true;
        this.systemCheckTime = moment();
      }
    });

  }

  onClickSysChkClo() {
    this.systemCheck = false;
  }

  onClickReport(){
    this.router.navigate(['report']);
  }

  getAllSdc() {
    this.cCService.getCenter().subscribe((allSdc: any) => {
      this.allSortSdc = allSdc.centerDetails.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
      this.allSdc = this.allSortSdc.filter(ele => ele.isCenterActive === "1")
      console.log(this.allSdc);
      this.totalSdc = this.allSdc.length - 1;
      //console.log(this.totalSdc);
      this.loader = true;
      this.cCService.getAllCameraBySdc(this.allSdc[this.setValue].accessId).subscribe((totalCam:any) =>{
        //console.log(totalCam);
        this.totalCamera = totalCam.cameraDetails;
      });
      if (localStorage.getItem('totalCamList') === null) {
        //console.log('ffffff');
        this.selectSdc = this.allSdc[this.setValue];
        //console.log(this.selectSdc);
        //console.log(this.setValue);
        if(this.selectSdc.isCenterActive === "1"){
          console.log("sdc on");
          this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((allCam: any) => {
            //console.log(allCam)
            this.cameraList = allCam.cameraDetails;
            if(this.cameraList.length === 0){
              this.next();
            }else{
              this.onStep = 'first';
              //console.log(this.cameraList)
              for (let a = 0; a < this.cameraList.length; a++) {
                const camAvailData = {
                  cameraDeviceId: this.cameraList[a].cameraDeviceId
                }
                this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                  //console.log(camAvail);
                  if (camAvail.success === 1) {
                    const camspeed = {
                      cameraDeviceId: this.cameraList[a].cameraDeviceId
                    }
                    this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                      //console.log(camSpeed);
                    });
                  } else { }
                });
                if (a + 1 === this.cameraList.length) {
                  this.onStep= 'second';
                  setTimeout(() => {
                    this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((cam: any) => {
                      //console.log(cam);
                      this.cameraList = cam.cameraDetails;
                      //console.log(this.cameraList);
                      for (let b = 0; b < this.cameraList.length; b++) {
                        setTimeout(() => {
                          if (this.cameraList[b].isCameraActive === '1') {
                            const onPrams = {
                              cameraDeviceId: this.cameraList[b].cameraDeviceId,
                              cameraId: this.cameraList[b].cameraId,
                              channelId: '12'
                            }
                            //console.log(onPrams);
                            this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                              //console.log(onCam);
                            });
                          } else { }
                          if (b + 1 === this.cameraList.length) {
                            this.onStep = 'third';
                            localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                            localStorage.setItem('locSetValue', JSON.stringify(this.setValue));
                            setTimeout(() => {
                              this.loader = false;
                              // this.cameraList.forEach(element => {
                              //   //console.log(element.cameraId);
                              //   jwplayer(element.cameraId).setup({
                              //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                              //     height: 500,
                              //     width: 500,
                              //   });
                              // });
                              this.finalCameraList = this.cameraList;
                              this.play();
                            }, 2000);
                          }
                        }, 1000 * (b + 1));
                      }
                    });
                  }, 2000);
                }
                //console.log(i)
              }
            }
          });
        }else{
          //console.log("sdc off");
          setTimeout(() => {
            this.next();
          }, 200);
          this.loader= false;
          this.finalCameraList = undefined;
        }

      } else {
        //console.log('vvvv')
        this.setValue = JSON.parse(localStorage.getItem('locSetValue'));
        //console.log(this.setValue);
        this.selectSdc = this.allSdc[this.setValue];
        //console.log(this.selectSdc);
        if(this.selectSdc.isCenterActive === "1"){
          console.log("sdc on");
          this.cameraList = JSON.parse(localStorage.getItem('totalCamList'));
          //console.log(this.cameraList);
          setTimeout(() => {
            this.loader = false;

            // this.cameraList.forEach(element => {
            //   jwplayer(element.cameraId).setup({
            //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
            //     height: 500,
            //     width: 500,
            //   });
            // });
            this.finalCameraList = this.cameraList;
            this.play();
          }, 2000);
        }else{
          //console.log("sdc off");
          setTimeout(() => {
            this.next();
          }, 200);
          this.loader = false;
          this.finalCameraList = undefined;

        }

      }
    });
  }
  testing() {
    this.next()
    //console.log('next function');
  }

  next() {
    this.setValue++;
    //console.log(this.setValue);
    this.selectSdc = this.allSdc[this.setValue];
    //console.log(this.selectSdc);
    clearInterval(this.intereval);
    if(this.selectSdc.isCenterActive === "1"){
      //console.log("sdc on");
      this.loader = true;
      this.cCService.getAllCameraBySdc(this.selectSdc.accessId).subscribe((totalCam:any) =>{
        //console.log(totalCam);
        this.totalCamera = totalCam.cameraDetails;
      });
      if (this.cameraList.length === 0) {
        this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((allCam: any) => {
          //console.log(allCam)
          this.cameraList = allCam.cameraDetails;
          //console.log(this.cameraList);
          if (this.cameraList.length === 0) {
            this.testing();
            //console.log('camera not assgin')
          } else {
            //console.log('camera assgin')
            for (let a = 0; a < this.cameraList.length; a++) {
              this.onStep = 'first';
              const camAvailData = {
                cameraDeviceId: this.cameraList[a].cameraDeviceId
              }
              this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                //console.log(camAvail)
                if (camAvail.success === 1) {
                  const camspeed = {
                    cameraDeviceId: this.cameraList[a].cameraDeviceId
                  }
                  this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                    //console.log(camSpeed);
                  });
                } else {
                  //cam not avil
                }
              });
              if (a + 1 === this.cameraList.length) {
                this.onStep = 'second';
                setTimeout(() => {
                  this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((cam: any) => {
                    //console.log(cam);
                    this.cameraList = cam.cameraDetails;
                    //console.log(this.cameraList);
                    for (let b = 0; b < this.cameraList.length; b++) {
                      setTimeout(() => {
                        if (this.cameraList[b].isCameraActive === '1') {
                          const onPrams = {
                            cameraDeviceId: this.cameraList[b].cameraDeviceId,
                            cameraId: this.cameraList[b].cameraId,
                            channelId: '12'
                          }
                          //console.log(onPrams);
                          this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                            //console.log(onCam);
                          });
                        } else { }
                        if (b + 1 === this.cameraList.length) {
                          this.onStep = 'third';
                          localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                          localStorage.setItem('locSetValue', JSON.stringify(this.setValue));
                          setTimeout(() => {
                            this.loader = false;
                            // this.cameraList.forEach(element => {
                            //   //console.log(element.cameraId);
                            //   jwplayer(element.cameraId).setup({
                            //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                            //     height: 500,
                            //     width: 500,
                            //   });
                            // });
                            this.finalCameraList = this.cameraList;
                            this.play();
                          }, 2000);
                        }
                      }, 1000 * (b + 1));
                    }
                  });
                }, 2000);
              }
            }
          }
        });
      } else {
        //console.log('ddddd')
        //console.log(this.cameraList);
        this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
        for (let e = 0; e < this.allCamera.length; e++) {
          this.onStep = 'first';
          setTimeout(() => {
            //console.log(this.allCamera[a].isCameraActive)
            if (this.allCamera[e].isCameraActive === '1') {
              const offPrams = {
                cameraDeviceId: this.allCamera[e].cameraDeviceId,
                cameraId: this.allCamera[e].cameraId,
                channelId: '12'
              }
              //console.log(offPrams);
              this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                //console.log(offCam);
              });
            }
          }, 1000 * (e + 1));
          if (e + 1 === this.allCamera.length) {
            this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((allCam: any) => {
              //console.log(allCam);
              this.cameraList = allCam.cameraDetails;
              //console.log(this.cameraList);
              if (this.cameraList.length === 0) {
                this.testing();                
              } else {
                for (let f = 0; f < this.cameraList.length; f++) {
                  const camAvailData = {
                    cameraDeviceId: this.cameraList[f].cameraDeviceId
                  }
                  this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                    //console.log(camAvail);
                    if (camAvail.success === 1) {
                      const camspeed = {
                        cameraDeviceId: this.cameraList[f].cameraDeviceId
                      }
                      this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                       // console.log(camSpeed);
                      });
                    } else {
                      //cam not avail
                    }
                  });
                  if (f + 1 === this.cameraList.length) {
                    setTimeout(() => {
                      this.onStep = 'second';
                      this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((cam: any) => {
                        this.cameraList = cam.cameraDetails;
                        //console.log(this.cameraList);
                        for (let g = 0; g < this.cameraList.length; g++) {
                          setTimeout(() => {
                            if (this.cameraList[g].isCameraActive === '1') {
                              const onPrams = {
                                cameraDeviceId: this.cameraList[g].cameraDeviceId,
                                cameraId: this.cameraList[g].cameraId,
                                channelId: '12'
                              }
                              //console.log(onPrams);
                              this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                                //console.log(onCam);
                              });
                            } else { }
                            if (g + 1 === this.cameraList.length) {
                              this.onStep = 'third';
                              localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                              localStorage.setItem('locSetValue', JSON.stringify(this.setValue));
                              setTimeout(() => {
                                this.loader = false;
                                // this.cameraList.forEach(element => {
                                //   //console.log(element.cameraId);
                                //   jwplayer(element.cameraId).setup({
                                //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                                //     height: 500,
                                //     width: 500,
                                //   });
                                // });
                                this.finalCameraList = this.cameraList;
                                this.play();
                              }, 2000);
                            }
                          }, 1000 * (g + 1));
                        }
                      });
                    }, 2000);
                  }
                }
              }
            });
          }
        }
      }
      
    }else{
      //console.log("sdc off");
      this.loader = false;
      if(this.totalSdc <= this.setValue){

      }else{
        setTimeout(() => {
          this.next();
        }, 200);
      }

      this.finalCameraList = undefined;
    }

  }

  previous() {
    this.setValue--;
    //console.log(this.setValue);
    this.selectSdc = this.allSdc[this.setValue];
    //console.log(this.selectSdc);
    clearInterval(this.intereval);
    if(this.selectSdc.isCenterActive === "1"){
      //console.log("sdc on");
      this.loader = true;
      this.cCService.getAllCameraBySdc(this.selectSdc.accessId).subscribe((totalCam:any) =>{
        //console.log(totalCam);
        this.totalCamera = totalCam.cameraDetails;
      });
      if (this.cameraList.length === 0) {
        this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((allCam: any) => {
          //console.log(allCam)
          this.cameraList = allCam.cameraDetails;
          //console.log(this.cameraList);
          if (this.cameraList.length === 0) {
            this.previous();
            //console.log('camera not assgin')
          } else {
            //console.log('camera assgin')
            for (let a = 0; a < this.cameraList.length; a++) {
              this.onStep ='first';
              const camAvailData = {
                cameraDeviceId: this.cameraList[a].cameraDeviceId
              }
              this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                //console.log(camAvail)
                if (camAvail.success === 1) {
                  const camspeed = {
                    cameraDeviceId: this.cameraList[a].cameraDeviceId
                  }
                  this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                    //console.log(camSpeed);
                  });
                } else {
                  //cam not avil
                }
              });
              if (a + 1 === this.cameraList.length) {
                setTimeout(() => {
                  this.onStep ='second';
                  this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((cam: any) => {
                    //console.log(cam);
                    this.cameraList = cam.cameraDetails;
                    //console.log(this.cameraList);
                    for (let b = 0; b < this.cameraList.length; b++) {
                      setTimeout(() => {
                        if (this.cameraList[b].isCameraActive === '1') {
                          const onPrams = {
                            cameraDeviceId: this.cameraList[b].cameraDeviceId,
                            cameraId: this.cameraList[b].cameraId,
                            channelId: '12'
                          }
                          //console.log(onPrams);
                          this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                            //console.log(onCam);
                          });
                        } else { }
                        if (b + 1 === this.cameraList.length) {
                          this.onStep = 'third';
                          localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                          localStorage.setItem('locSetValue', JSON.stringify(this.setValue));
                          setTimeout(() => {
                            this.loader = false;
                            // this.cameraList.forEach(element => {
                            //   //console.log(element.cameraId);
                            //   jwplayer(element.cameraId).setup({
                            //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                            //     height: 500,
                            //     width: 500,
                            //   });
                            // });
                            this.finalCameraList = this.cameraList;
                            this.play();
                          }, 2000);
                        }
                      }, 1000 * (b + 1));
                    }
                  });
                }, 2000);
              }
            }
          }
        });
      } else {
        //console.log('ddddd')
        //console.log(this.cameraList);
        this.onStep = 'first';
        this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
        for (let e = 0; e < this.allCamera.length; e++) {
          setTimeout(() => {
            //console.log(this.allCamera[a].isCameraActive)
            if (this.allCamera[e].isCameraActive === '1') {
              const offPrams = {
                cameraDeviceId: this.allCamera[e].cameraDeviceId,
                cameraId: this.allCamera[e].cameraId,
                channelId: '12'
              }
              //console.log(offPrams);
              this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                //console.log(offCam);
              });
            }
          }, 1000 * (e + 1));
          if (e + 1 === this.allCamera.length) {
            this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((allCam: any) => {
              //console.log(allCam);
              this.cameraList = allCam.cameraDetails;
              //console.log(this.cameraList);
              if (this.cameraList.length === 0) {
                this.previous();
              } else {
                for (let f = 0; f < this.cameraList.length; f++) {
                  const camAvailData = {
                    cameraDeviceId: this.cameraList[f].cameraDeviceId
                  }
                  this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                    //console.log(camAvail)
                    if (camAvail.success === 1) {
                      const camspeed = {
                        cameraDeviceId: this.cameraList[f].cameraDeviceId
                      }
                      this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                        //console.log(camSpeed);
                      });
                    } else {
                      //cam not avail
                    }
                  });
                  if (f + 1 === this.cameraList.length) {
                    setTimeout(() => {
                      this.onStep = 'second';
                      this.cCService.getAllActiveCameraBySdc(this.selectSdc.accessId).subscribe((cam: any) => {
                        this.cameraList = cam.cameraDetails;
                        //console.log(this.cameraList);
                        for (let g = 0; g < this.cameraList.length; g++) {
                          setTimeout(() => {
                            if (this.cameraList[g].isCameraActive === '1') {
                              const onPrams = {
                                cameraDeviceId: this.cameraList[g].cameraDeviceId,
                                cameraId: this.cameraList[g].cameraId,
                                channelId: '12'
                              }
                              //console.log(onPrams);
                              this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                                //console.log(onCam);
                              });
                            } else { }
                            if (g + 1 === this.cameraList.length) {
                              this.onStep = 'third';
                              localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                              localStorage.setItem('locSetValue', JSON.stringify(this.setValue));
                              setTimeout(() => {
                                this.loader = false;
                                // this.cameraList.forEach(element => {
                                //   //console.log(element.cameraId);
                                //   jwplayer(element.cameraId).setup({
                                //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                                //     height: 500,
                                //     width: 500,
                                //   });
                                // });
                                this.finalCameraList = this.cameraList;
                                this.play();
                              }, 2000);
                            }
                          }, 1000 * (g + 1));
                        }
                      });
                    }, 2000);
                  }
                }
              }
            });
          }
        }
      }
    }else{
      console.log("sdc off");
      this.play();
      this.loader= false;
      this.finalCameraList = undefined;
    }

  }

  play() {
    //console.log('auto play');
    this.autoPlay = true;
    //console.log(JSON.parse(localStorage.getItem('locSetValue')));
    this.intereval = setInterval(() => {
      if (this.totalSdc === JSON.parse(localStorage.getItem('locSetValue'))) {
        this.setValue = -1;
        setTimeout(() => {
          this.next();
          //console.log('auto play end');
          // console.log(this.setValue);
        }, 1000);
      } else {
        this.next()
        //console.log('auto play else')
      }
    }, 60000);
  }

  pause() {
    //console.log('auto play stop')
    this.autoPlay = false;;
    clearInterval(this.intereval);
  }


    /* ===============================================================
    Image Capture
  =============================================================== */
  captureImage() {
    this.disableBtn = 'click-disable';
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      capturedDate: moment().format("YYYY-MM-DD"),
      capturedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.classService.imageCapture(user).subscribe((data: any) => {
      //console.log(data);
      this.capturedImageUrl = data.imagePath;
      this.captureImageSec = true;
      setTimeout(() => {
        this.disableBtn = '';
      }, 1000);
      setTimeout(() => {
        this.captureImageSec = false;
      }, 3500);
    });
  }

  /* ===============================================================
  Video Recording Start
  =============================================================== */
  recordingStart() {
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
    }
    this.classService.videoRecStart(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = false;
        this.stopRecBtn = true;
        this.toastr.info('The maximum length of the video to record is 1 minute', 'Recording started');
        this.autoStopVidRec();
      } else { }
    });
  }

  /* ===============================================================
    Video Recording Stop
  =============================================================== */
  recordingStop() {
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      recordedDate: moment().format("YYYY-MM-DD"),
      recordedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.classService.videoRecStop(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = true;
        this.stopRecBtn = false;
        this.toastr.info('You can check the recorded video in the history', 'Recording stopped');
        clearInterval(this.intervel);
      } else { }
    });
  }

  /* ===============================================================
    Video Auto Stop
  =============================================================== */
  autoStopVidRec() {
    this.intervel = setInterval(() => {
      this.recordingStop();
      this.startRecBtn = true;
      this.stopRecBtn = false;
    }, 50000)
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/cCSearch.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script); 
  }

  ngOnInit() {
    this.getAllSdc();

    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;
    });

    setTimeout(() => {
      this.loadScript();
    }, 10000);
  }

}
