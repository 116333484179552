import { Component, OnInit,  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../services/authService/auth.service';
import { AuthGuard } from '../../guards/auth.guard';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  messageClass;
  message;
  processing = false;
  form;
  resetPwdForm;
  previousUrl;
  dynamicUrl;
  show: boolean = false;
  accessId;
  submitBtn;
  btnProgress='hide';
  btnText;
  disableBtn:boolean;
  submitBtnShowHide:boolean=true;
  resetPasswordMove:boolean;
  resertPwdSec;
  resetEmailAnima;

  optSec='hide';
  resetPwdOtpForm;
  firstSubmit='show';
  secondSubmit ='hide';
  otpVerifySec ='hide';
  resetEmail;
  otpVerifyForm;


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private authGuard: AuthGuard
  ) {
    this.createForm();
    this.createFormPwdReset();
    this.createFormOtp();
    this.createNewPws();
  }
  /* ===============================================================
      Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      password: [null, [Validators.required, Validators.pattern("^.{3,20}$")]]
    });
  }
  createFormPwdReset() {
    this.resetPwdForm = this.formBuilder.group({
      resetEmail: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
    });
  }

  createFormOtp() {
    this.resetPwdOtpForm = this.formBuilder.group({
      otp:  [null, [Validators.required, Validators.pattern("^.{3,10}$")]]
    });
  }

  createNewPws() {
    this.otpVerifyForm = this.formBuilder.group({
      newPassword: [null, [Validators.required, Validators.pattern("^.{5,15}$")]],
      confirmPassword: ['', [Validators.required, Validators.pattern("^.{5,15}$")]]
    }, { validator: this.matchingPasswords('newPassword', 'confirmPassword') });
  }
  matchingPasswords(Password, Confirmpassword) {
    return (group: FormGroup) => {
      if (group.controls[Password].value === group.controls[Confirmpassword].value) {
        return null;
      } else {
        return { 'matchingPasswords': true }
      }
    }
  }

  disableForm() {
    this.form.controls['username'].disable();
    this.form.controls['password'].disable();
  }

  enableForm() {
    this.form.controls['username'].enable();
    this.form.controls['password'].enable();
  }

  /* ===============================================================
      Login Function
  =============================================================== */
  onLoginSubmit() {
    this.resetPasswordMove= true;
    this.disableBtn=true;
    this.btnText = 'hide';
    this.submitBtn = 'button-loading';
    setTimeout(()=>{
      this.submitBtn = 'hide';
      this.submitBtnShowHide = false;
    },220);
    this.btnProgress= '';
    setTimeout(()=>{
      this.btnProgress= 'hide';
    },3000);
    this.processing = true;
    this.disableForm();
    const user = {
      username: this.form.get('username').value,
      password: this.form.get('password').value
    }
    this.authService.login(user).subscribe((data:any) => {     
      if (data.success == 0) {
        setTimeout(()=>{
          this.submitBtn = 'button-status-error';
        },2800);
        setTimeout(()=>{
          this.disableBtn=false;
          this.submitBtn = '';
          this.btnText = '';
          this.submitBtnShowHide = true;
          this.resetPasswordMove= false;
        },4000);
        this.toastr.error('Login Failed','Error');
        this.processing = false;
        this.enableForm();
        this.form.controls['password'].reset();
      } else {
        this.accessId = data.user.accessId;
        this.authService.storeUserData(data.token);
        let key = 'UserInfo';
        localStorage.setItem(key, this.accessId);
        if (this.previousUrl) {
          this.router.navigate([this.previousUrl]);
        } else {
          setTimeout(()=>{
            this.submitBtn = 'button-status-success';
          },2800);
          setTimeout(()=>{
            this.router.navigate(['/dashboard']);
          },3400);
        }
      }
    });
  }

  /* ===============================================================
    Reset Password
  =============================================================== */
  /* Reset Password Section Open */
  onclickResetPwdSec(){
    this.resertPwdSec = 'show-sec';
    setTimeout(()=>{
      this.resetEmailAnima = 'reset-email-animation';
    },300);
  }
  /* Reset Password Section Close */
  passwordResetBck(){
    //console.log('ffffff')
    this.resertPwdSec = '';
    this.resetEmailAnima = '';
    this.resetPwdForm.reset();
    this.resetPwdOtpForm.reset();
    this.firstSubmit = 'show';
    this.secondSubmit = 'hide';
    this.optSec = 'hide';
  }

  /* Reset Password Submit */
  onRestPwdForm(email){
    //console.log(email);
    this.resetEmail = email.resetEmail
    const user = {
      email: email.resetEmail
    }
    //console.log(user);
    this.authService.forgotPassword(user).subscribe((data:any) =>{
      // this.resertPwdSec = '';
      // this.resetEmailAnima = '';
      if(data.success == 1){
        this.optSec = 'show';
        this.firstSubmit = 'hide';
        this.secondSubmit = 'show';
      }else{
        this.resetPwdForm.reset();
        this.toastr.info(data.message, 'Info');
      }
    });
  }

  onRestPwdOtpForm(otp){
    const user ={
      otp: this.resetPwdOtpForm.get('otp').value,
      email: this.resetEmail
    }
    this.authService.verifyOtp(user).subscribe((data:any)=>{
      //console.log(data);
      if(data.success == 1){
        this.otpVerifySec = 'show-sec';
        this.resetPwdOtpForm.reset();
        this.resetPwdForm.reset();
      }else{
        this.resetPwdOtpForm.reset();
        this.toastr.info(data.message, "Info");
      }
    })
  }

  onCreateNewPws(){
    const user= {
      email : this.resetEmail,
      password : this.otpVerifyForm.get('newPassword').value,
      updatedBy: this.resetEmail,
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    this.authService.updatePassword(user).subscribe((data:any) =>{
      if(data.success ==1){
        this.otpVerifyForm.reset();
        this.resertPwdSec = '';
        this.resetEmailAnima = '';
        this.otpVerifySec = '';
        this.optSec = 'hide';
        this.firstSubmit = 'show';
      }else{

      }
    });
  }

  newPswToLogin(){
    this.otpVerifyForm.reset();
    this.resertPwdSec = '';
    this.resetEmailAnima = '';
    this.otpVerifySec = '';
    this.optSec = 'hide';
    this.firstSubmit = 'show';
  }

  // click event function toggle
  password() {
    this.show = !this.show;
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script); 
  }

  ngOnInit() {
    this.loadScript();
    if (this.authGuard.redirectUrl) {
      this.messageClass = 'alert alert-danger';
      this.message = 'You must be logged in to view that page.';
      this.previousUrl = this.authGuard.redirectUrl;
      this.authGuard.redirectUrl = undefined;
    }
  }
}
