import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'batchSearch'
})
export class BatchSearchPipe implements PipeTransform {

  transform(allBatch: any, search: any): any {
    if (!allBatch || !search) {
      return allBatch;
    }
    return allBatch.filter(allbatch =>
      allbatch.batchCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allbatch.batchHours.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allbatch.tpCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allbatch.tpName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allbatch.centerLocation.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allbatch.districtName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
