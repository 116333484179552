import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import * as moment from 'moment';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { AuthService } from 'src/app/services/authService/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-all-batch-list',
  templateUrl: './all-batch-list.component.html',
  styleUrls: ['./all-batch-list.component.css']
})
export class AllBatchListComponent implements OnInit {

  username: any;
  allBatch:any;
  loader= 'show';
  batchUpdateType = 'others';
  updateBatchForm: FormGroup;
  updateBatchFormCodeHr: FormGroup;
  selectBatch: any;
  updateBatchSec: any;
  batchStrTime: any;
  batchEndTime: any;
  creBtn: boolean = true;
  startDate:any;
  endDate: any;
  startDt: any;
  endDt: any;
  mainSec: boolean = false;


  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private centerService: CenterDetailsService,

  ) { 
    this.updateFormBatch();
    this.updateFormBthCodeHour();
  }

  updateFormBthCodeHour() {
    this.updateBatchFormCodeHr = this.formBuilder.group({
      updateBatchId: [null, [Validators.required, Validators.pattern("^.{3,50}$")]],
      updateTotalHour: [null, [Validators.required, Validators.pattern("^.{2,4}$")]],
    });
  }

  updateFormBatch() {
    this.updateBatchForm = this.formBuilder.group({
      upDtstrendDate: [null, [Validators.required]],
      upDtBatchStartTime: [null, [Validators.required]],
      upDtBatchEndTime: [null, [Validators.required]],
      upDtBatchType: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;

    });
    this.adminService.getBacthList().subscribe((data:any)=>{
      //console.log(data);
      this.allBatch = data.batchDetails;
      this.loader= 'hide';

    });
  }


    /* ===============================================================
      Batch Update
  =============================================================== */
  onClickOthers() {
    this.batchUpdateType = 'others';
    //console.log(this.batchUpdateType);
    this.updateBatchFormCodeHr.reset();
  }

  onClickCodeHour() {
    this.batchUpdateType = 'codehour';
    this.updateBatchForm.reset();
  }


  onClickBtUpDt(batch) {
    this.selectBatch = batch;
    console.log(batch);
    this.updateBatchSec = 'overlay-visible';
  }

  somethingChanged(value) {
    if (value.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  onChange(value) {
    if (value != null) {
      this.startDt = value[0];
      this.endDt = value[1];
    } else { }

  }

  onChangeUpDtStrTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchStrTime = this.datePipe.transform(value._d, 'HH:mm:ss');
    } else { }
    //console.log(this.batchStrTime);
  }

  onChangeUpdtEndTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchEndTime = this.datePipe.transform(value._d, 'HH:mm:ss');
      //console.log(this.batchEndTime);
    } else { }
  }

  onUpdateBatch() {
    this.creBtn = false;
    this.loader= 'show';
    this.startDate = this.datePipe.transform(this.startDt._d, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDt._d, 'yyyy-MM-dd');
    const updateData = {
      batchAccessId: this.selectBatch.accessId,
      batchStratDate: this.startDate,
      batchEndDate: this.endDate,
      batchStartTime: this.batchStrTime,
      batchEndTime: this.batchEndTime,
      batchType: this.updateBatchForm.get('upDtBatchType').value,
      schemeAccessId: this.selectBatch.schemeAccessId,
      schemeCode: this.selectBatch.schemeCode,
      tpAccessId: this.selectBatch.tpAccessId,
      tpCode: this.selectBatch.tpCode,
      tpName: this.selectBatch.tpName,
      centerAccessId: this.selectBatch.centerAccessId,
      centerCode: this.selectBatch.centerCode,
      centerName: this.selectBatch.centerName,
      contactPersonName: this.selectBatch.contactPersonName,
      contactPersonMobile: this.selectBatch.contactPersonMobile,
      centerLocation: this.selectBatch.centerLocation,
      centerAddress: this.selectBatch.centerAddress,
      districtCode: this.selectBatch.districtCode,
      districtName: this.selectBatch.districtName,
      courseAccessId: this.selectBatch.courseAccessId,
      courseCode: this.selectBatch.courseCode,
      courseName: this.selectBatch.courseName,
      updatedBy: this.username,
      updatedAt: moment().format("YYYY-MM-DD HH:mm:s")
    }
    console.log(updateData);
    this.centerService.updateBatch(updateData).subscribe((info: any) => {
      console.log(info);
      
      if (info.success === 1) {
        this.adminService.getBacthList().subscribe((data:any)=>{
          this.allBatch = data.batchDetails;
          this.creBtn = true;
          this.loader= 'hide';
          this.updateBatchSec = '';
          this.updateBatchForm.reset();
          this.selectBatch = undefined;
          this.toastr.success('Batch updated.')
        });
      } else { }
    });
  }


  onUpdateBatchCodeHr() {
    this.creBtn = false;
    this.loader= 'show';
    const updateData = {
      batchAccessId: this.selectBatch.accessId,
      batchCode: this.updateBatchFormCodeHr.get('updateBatchId').value,
      batchHours: this.updateBatchFormCodeHr.get('updateTotalHour').value
    }
    console.log(updateData)
    this.centerService.updateBatchCodeHour(updateData).subscribe((info: any) => {
      //console.log(info)
      if (info.success === 1) {
        this.adminService.getBacthList().subscribe((data:any)=>{
          this.allBatch = data.batchDetails;
          this.creBtn = true;
          this.loader= 'hide';
          this.updateBatchSec = '';
          this.updateBatchFormCodeHr.reset();
          this.selectBatch = undefined;
          this.toastr.success('Batch updated.')
        });
      } else { }
    });
  }


}
